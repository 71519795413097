import { useState, useEffect,createContext,useContext, Dispatch, SetStateAction, useMemo } from 'react'
import { IApplyFormData, crtlInfo } from '../interfaces/EpModal';

export const SysContext = createContext<SysInfoCtx>(
  {
    sharedState: '',
    setSharedState: ()=>{},
    role:'',
    setRole: ()=>{}
  }
);

export declare interface SysInfoCtx {
  sharedState: string;
  setSharedState: (value:string)=>void;
  role:string
  setRole: (value:string)=>void;
}

export declare interface ApplySysContextProps {
  children?: React.ReactNode;
}

//const [crtlInfoList, setCtrlInfoList] = useState<crtlInfo[] | null>(null);
const ApplySysContext = ({ children }: ApplySysContextProps)=>{
  const [sharedState, setSharedState] = useState<string>('');
  const [crtlInfoList, setCtrlInfoList] = useState<crtlInfo[] | null>(null);
  const [applyInfo, setApplyInfo] = useState<IApplyFormData | null>(null);
  const [role, setRole] = useState<string>('');
  return (
    <SysContext.Provider value={{
      sharedState,
      setSharedState,
      role,
      setRole
    }}>
      {children}
    </SysContext.Provider>
  );
}
export default ApplySysContext;

export function useSysInfoContext() {
  return useContext(SysContext);
}

