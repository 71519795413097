import React from 'react';

const LoadingScreen: React.FC = () => {
    // 定義內嵌樣式
    const overlayStyle: React.CSSProperties = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    };

    const spinnerStyle: React.CSSProperties = {
        width: '50px',
        height: '50px',
        border: '5px solid rgba(255, 255, 255, 0.3)',
        borderTop: '5px solid #fff',
        borderRadius: '50%',
        animation: 'spin 1s linear infinite',
    };

    const textStyle: React.CSSProperties = {
        color: '#fff',
        fontSize: '18px',
        marginTop: '20px',
        textAlign: 'center',
        animation: 'blink 1.5s infinite', // 添加閃爍動畫
    };

    // 定義 keyframes 動畫
    const keyframes = `
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
        @keyframes blink {
            0%, 100% { opacity: 1; }
            50% { opacity: 0; }
        }
    `;

    return (
        <>
            {/* 動態添加 keyframes */}
            <style>{keyframes}</style>
            {/* 全屏加載畫面 */}
            <div style={overlayStyle}>
                <div style={spinnerStyle}></div>
                <p style={textStyle}>Loading ...</p>
            </div>
        </>
    );
};

export default LoadingScreen;
