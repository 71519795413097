

import React, { ChangeEvent, useEffect, useState } from 'react'
import IPageProps from '../../interfaces/page';
import StudentFunctionBar from './component/studentFunctionBar';

import TopNavPage1 from '../topNav1';
import FooterPage from '../footer';
import ExpertFunctionBar from './component/expertFunctionBar';
import { exportToExcel } from './tool/helper';






// https://attendance-67917bb.web.app/digit/epStudHome.html
const EpExpertComment: React.FunctionComponent<IPageProps> =() => {

  


return (
    <>
  
    <TopNavPage1 />
    <div className="section pt-3">
    <div className="container px-3 px-md-4">
    <ExpertFunctionBar/>
      <div className="d-flex my-3">
        <div style={{width:"112px"}}></div>
        <div className="text-center h5 mx-auto mb-0">參考評語</div>
    
        <div className="btn text-second bg-second pointer mb-0" onClick={()=>{ exportToExcel('opin','參考評語')}}>匯出EXCEL</div>
      </div>

      <div className="card p-4 pt-3">
        <div className="table-responsive py-3">
          <table id="opin" className="text-sm table table-bordered table-striped mb-0">
            <thead>
              <tr className="fw-bold text-second" style={{background: "#d9dce6"}}>
                <th className="p-2 text-nowrap">階段</th>
                <th className="p-2 text-nowrap">類別</th>
                <th className="p-2 text-nowrap" style={{width: "75%"}}>評語</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={5} className="text-nowrap fw-bold">1-1 提出探究題目</td>
                <td>正面</td>
                <td>大家提出很多不同領域想探索的題目，很不錯。</td>
              </tr>
              <tr>
                <td>正面</td>
                <td>大家很認真提出看法和搜尋資料，非常好。</td>
              </tr>
              <tr>
                <td>正面</td>
                <td>大家提出的探究題目多元，最後能有共識，就是好的開始。</td>
              </tr>
              <tr>
                <td>建議</td>
                <td>1-1想要的探究題目只有一個，很好奇大家是否還有想到其他題目?</td>
              </tr>
              <tr>
                <td>建議</td>
                <td>很希望知道你們每位成員提出的探究題目，目前並沒有看到1-1的內容。</td>
              </tr>
              <tr>
                <td rowSpan={10} className="text-nowrap fw-bold">1-2 決定探究題目</td>
                <td>正面</td>
                <td>XXX的題目很有意義，期待你們的探索。</td>
              </tr>
              <tr>
                <td>正面</td>
                <td>一群對XXX很有興趣的夥伴共同研究，真棒!</td>
              </tr>
              <tr>
                <td>正面</td>
                <td>主題相信是每位隊員都很有興趣的題目，值得探究！</td>
              </tr>
              <tr>
                <td>正面</td>
                <td>一開始探究題目太大，後面可以看出隊員試圖縮小聚焦的過程，這是很好的學習呢!</td>
              </tr>
              <tr>
                <td>建議</td>
                <td>要思考主題是否可持續進行探究的可能，以及可以搜尋到的資料是否充足；蒐集資料的方式請以「網路蒐集」、「數位閱讀」為主。(例如題目為如何「清除班上垃圾」、「圈圈叉叉有必勝攻略」嗎？)
                </td>
              </tr>
              <tr>
                <td>建議</td>
                <td>主題XXX稍抽象，建議加上副標題，直接敘述你們真正的探究問題，讓讀者一目了然。(例如「甜蜜的負荷」的主題，加上「手搖飲料」。</td>
              </tr>
              <tr>
                <td>建議</td>
                <td>主題和子題建議用完整句表達，探究方向可以更明確。(例如有隊伍用「硫磺」一詞為主題)</td>
              </tr>
              <tr>
                <td>建議</td>
                <td>題目設定是要回答 Yes or No （可以或不可以）， 用 How （如何讓...確實執行而有效）會不會更恰當?</td>
              </tr>
              <tr>
                <td>建議</td>
                <td>題目本身建議改成較不帶觀點的客觀敘述。(例如「為什麼賞鳥活動有助於推動環境保育」改為「賞鳥活動與環境保育的關係」。</td>
              </tr>
              <tr>
                <td>建議</td>
                <td>XXX有很多目的，不僅是"YYY"，若改為「ZZZ」，會不會更好?</td>
              </tr>
              <tr>
                <td rowSpan={2} className="text-nowrap fw-bold">1-3 預測可能的結果</td>
                <td>正面</td>
                <td>遇到困難時，能尋求老師的建議，並懂得分工合作與相互諒解，很好。</td>
              </tr>
              <tr>
                <td>建議</td>
                <td>未來蒐集資料時要根據可靠的科學網站，才能有根據的推論可能的原因。</td>
              </tr>
              <tr>
                <td rowSpan={2} className="text-nowrap fw-bold">1-4 困難與解決</td>
                <td>正面</td>
                <td>大家遇到困難和解決問題的態度很不錯。</td>
              </tr>
              <tr>
                <td>建議</td>
                <td>所有隊員能聚在一起的時間可能有限，建議仍是要分工蒐集資料，分析時再聚集討論才會有效率。</td>
              </tr>
              <tr>
                <td rowSpan={8} className="text-nowrap fw-bold">2-1 擬定探究子題</td>
                <td>正面</td>
                <td>很用心擬定子題與探究架構。</td>
              </tr>
              <tr>
                <td>建議</td>
                <td>子題「XXX」，建議修改為「YYY」，較為中立。(例如子題「長輩為何不接受ACGN文化」，修改為「長輩對ACGN的看法」。)</td>
              </tr>
              <tr>
                <td>建議</td>
                <td>子題範圍廣泛，建議再思考各項子題與主題之間的連結是否緊密。</td>
              </tr>
              <tr>
                <td>建議</td>
                <td>子題的安排建議加上「取消午休的影響」，並將正反面的影響並列，才能使得探究結果更客觀。</td>
              </tr>
              <tr>
                <td>建議</td>
                <td>各子題都由全體隊員負責探究，建議能適當分工。</td>
              </tr>
              <tr>
                <td>建議</td>
                <td>子題若能增加「時空膠囊」項目，探究這個主題對XXX，是不是更好 ?</td>
              </tr>
              <tr>
                <td>建議</td>
                <td>從1-3預測的項目可知，隊員希望透過探究瞭解 ......，建議探究子題能聚焦在這幾個方向。</td>
              </tr>
              <tr>
                <td>建議</td>
                <td>子題的順序建議調整，如先要定義何謂XXX。</td>
              </tr>
              <tr>
                <td rowSpan={5} className="text-nowrap fw-bold">2-2 繪製探究架構</td>
                <td>正面</td>
                <td>架構圖使用手繪也很好，可以清楚表達比較重要，何況你們也畫得很棒。電腦製作以後再學習即可。</td>
              </tr>
              <tr>
                <td>建議</td>
                <td>探究架構圖，指的是由主題出發，往下列出你們的子題，接著再將你們的子題分成更細的項目，以利之後的探究，請修正架構圖。</td>
              </tr>
              <tr>
                <td>建議</td>
                <td>架構圖的主題和1-2的題目最好能夠一致。</td>
              </tr>
              <tr>
                <td>建議</td>
                <td>探究架構圖目前只有兩層，建議能發展至少三層，並請轉正後再上傳。</td>
              </tr>
              <tr>
                <td>建議</td>
                <td>探究架構圖使用了一些照片，要注意版權問題喔!</td>
              </tr>
              <tr>
                <td rowSpan={3} className="text-nowrap fw-bold">鼓勵的話</td>
                <td>正面</td>
                <td>加油，期待你們的探究結果！</td>
              </tr>
              <tr>
                <td>正面</td>
                <td>大家很認真，期待你們的探究發現喔。</td>
              </tr>
              <tr>
                <td>正面</td>
                <td>對XXX充滿好奇與關懷，值得鼓勵!</td>
              </tr>
              <tr>
                <td rowSpan={2} className="text-nowrap fw-bold">綜合評論</td>
                <td>正面</td>
                <td>階段1-4、2-3可以看到成員共同討論，以及一起抓重點、解決困難，很棒的團隊合作！</td>
              </tr>
              <tr>
                <td>建議</td>
                <td>本競賽請以「網路蒐尋」、「數位閱讀」為主，進行蒐集、瀏覽、判斷、整合；問卷調查為輔。</td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>


{/* 列印部分 */}



      <div className="pt-3 mt-4"></div>

    </div>
    </div>
      <FooterPage />
    </>
   
    )


    } 

export default EpExpertComment;