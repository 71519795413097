import { type } from 'os'
import React, { useEffect, useState } from 'react'
import { PTeamModal } from '../../components/modal/pteammodal'
import { IEPGroup, IEPGroupAward, IEPGroupType } from '../../interfaces/EPGroup'
import IPageProps from '../../interfaces/page'
import { handleLink } from '../../modules/utils'
import { getEPGroup, getEPGroupByYr, getEPGroupType } from '../../services/EPDataService'
import FooterPage from '../footer'
import TopNavPage1 from '../topNav1'
import TopNavPage2 from '../topNav2'
import { getDrGroupAward } from '../../services/EP/EPApplyGroupService'
import AllOpinion from './component/allOpinion'
import SimpleOpinion from './component/simpleOpinion'


const EpExcellentPage: React.FunctionComponent<IPageProps> = props => {
    const [epAward,setEpAward]=useState<IEPGroupAward[]>([]);
    const [classList,setClassList]=useState<string[]>([]);
    const [currClass,setCurrClass]=useState('');

    const [groupList,setGroupList]=useState<IEPGroupAward[]>([]);
    const [currGroup,setCurrGroup]=useState<IEPGroupAward>();

    const loadGroupByClass=(classno:string)=>{
        const filteredAward=epAward.filter((item:IEPGroupAward)=>item.Class===classno && item.Prize.includes('特優'));
      
        filteredAward.sort((a,b)=>a.Groupno.localeCompare(b.Groupno));
        setGroupList(filteredAward);
        setCurrGroup(filteredAward[0]);
    }

    const loadDate=async ()=>{
        const data=await getDrGroupAward();
        const award = data as IEPGroupAward[];
        setEpAward(award);
        const allClass=award.map((item:IEPGroupAward)=>item.Class);
        const classList=Array
                        .from(new Set(allClass))
                        .sort((a,b)=>b.localeCompare(a));
        setClassList(classList);
        setCurrClass(classList[0]);
        loadGroupByClass(classList[0]);
    }

    useEffect(()=>{
        if(currClass){
            loadGroupByClass(currClass);
        }
    },[currClass]);

    useEffect(()=>{
        loadDate();    
        console.log(epAward);
    },[epAward.length]);

    return (
        <>
            <TopNavPage2/>
            <div className="section pt-3">
                <div className="container px-3 px-md-4">
                    <div className="d-flex align-items-center justify-content-between flex-column flex-md-row">
                        <h5 className="title mb-3 me-3">線上探究<small className="ms-2">EP,Explore Process</small></h5>
                        <h5 className="order-2 order-md-1 mb-3">特優作品</h5>
                        <div className="order-1 order-md-2 mb-3 text-center text-md-end" style={{width: '232px'}}>
                            <button type="button" className="btn btn-outline-dark mb-0" onClick={()=>handleLink('/eptest')}>
                                <i className="fas fa-chevron-left"></i>
                                返回 EP 線上探究
                            </button>
                        </div>
                    </div>                
                    <div className="d-flex flex-wrap justify-content-start">
                        <div className="dropdown me-4 mb-3 mb-sm-0">
                            <button className="btn text-second bg-second dropdown-toggle mb-0" type="button" id="year"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                {
                                    currClass.includes('市') ? currClass.trim() : currClass.trim() + '全國聯賽'
                                    
                                    
                                }
                            </button>
                            <ul className="dropdown-menu px-2 py-3" aria-labelledby="year">
                                {
                                    classList.map((item:string)=>(
                                        <li key={item}>
                                            <a className="dropdown-item d-flex" href="#" onClick={()=>setCurrClass(item)}>
                                                {
                                                    item.includes('市') ? item.trim() : item.trim() + '全國聯賽'
                                                }
                                            </a>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className="dropdown">
                            <button className="btn text-second bg-second dropdown-toggle mb-0" type="button" id="schoolno"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                {
                                    currGroup?.Groupno.trim()+'-'+currGroup?.Schoolname.trim()+'-'+currGroup?.Groupname.trim()
                                }
                            </button>
                            <ul className="dropdown-menu px-2 py-3" aria-labelledby="schoolno">
                                {
                                    groupList.map((item)=>(
                                        <li key={item.Groupno}>
                                            <a className="dropdown-item d-flex" href="#" onClick={()=>setCurrGroup(item)}>
                                                {
                                                    item.Groupno.trim()+'-'+item.Schoolname.trim()+'-'+item.Groupname.trim()
                                                }
                                            </a>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    {
                        (currGroup?.Class) ?
                            <SimpleOpinion 
                                propClassName={currGroup.Class.trim()} 
                                propGroup={{...currGroup,Groupno:currGroup.Groupno.trim()}}
                                propSchoolName={currGroup.Schoolname.trim()}
                             />
                        : null
                        
                    }
                </div>                
            </div>
            <FooterPage/>

        </>
        
    );
}

export default EpExcellentPage;