
import axios, { AxiosError } from 'axios'
import  config from '../../config/config';

// 以學年度、控制名稱及現在時間點查詢控制資訊
// Get DrControl by Class and Now
// Get /api/DrTable/control/now/class/:class/control/:control
// Response:
// {
//   "Control":"6-4-1"   // 網頁編號
//   "SubControl":""     // 子控制項
//   "Action": "Enable"  // 啟動
//   "StageNo":"1"       // 階段編號
//   "StageName":"報名"  // 階段名稱
// } `

// const getDrControlByClassControlNow = asyncHandler(async(req: Request, res: Response) => {
    export const getDrControlByClassControlNow = async  (className :string ,control:string)=>{
        const url=config.server.url+`/api/DrTable/control/now/class/${className}/control/${control}`
        console.log("url:"+url)
            return new Promise((resolve, reject) => {
              axios.get(url)
                .then(response => {
                  resolve(response.data);
                })
                .catch(error => {
                  console.error('Error fetching data:', error);
                  reject(error); //
                });
            });
      
      
      }


      


// 以學年度及時間點查詢控制資訊
// Get DrControl by Class and Now
// Get /api/DrTable/control/now/class/:class
// Response:
// {
//   "Control":"6-4-1"   // 網頁編號
//   "SubControl":""     // 子控制項
//   "Action": "Enable"  // 啟動
//   "StageNo":"1"       // 階段編號
//   "StageName":"報名"  // 階段名稱
// } 

// const getDrControlByClassNow = asyncHandler(async(req: Request, res: Response) => {

  export const getDrControlByClassNow = async  (className :string )=>{
    const url=config.server.url+`/api/DrTable/control/now/class/${className}`
   
        return new Promise((resolve, reject) => {
          axios.get(url)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              console.error('Error fetching data:', error);
              reject(error); //
            });
        });
  
  
  }



  export const getDrControlByRole = async  (role :string='guest'   )=>{
   
    const today = new Date();
    const dateTime = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')} `
    const url=config.server.url+`/api/DrTable/control/now/role/${role}/nowDateTime/${dateTime}`
        return new Promise((resolve, reject) => {
          axios.get(url)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              console.error('Error fetching data:', error);
              reject(error); //
            });
        });
  
  
  }
