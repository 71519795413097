import React, { useEffect, useState } from 'react';
import { IApplyFormData, IApplyTopic, IGroupInfo, IGroupStudent, IOpinion, ISearchDataSummary, TeacherGroupInfo } from '../../../interfaces/EpModal';
import { getEPApplyByNo } from '../../../services/EP/EpLoginService';
import { getDrGroupPrimeByYrApplyNo, getDrStudentByGroup } from '../../../services/EP/EPApplyGroupService';
import { Export2Word, ReacherArPictureName_before, currentClass, currentTempGroup, downloadImage, formatDate, formatDateTime, getFileNameString, getLoginInfo, modeGlobal, replaceNewline, showJsonObj } from '../tool/helper';
import { getOpinionByGroupNo, getOpinionByQueryGroupNo, getSubSummarySubCollectByGroupNo, getSubTopicByGroupNo } from '../../../services/EP/EpApplyExploreService';
import { IEPGroupAward } from '../../../interfaces/EPGroup';
import { tr } from 'date-fns/locale';
import { ObjectModal } from '../../../components/modal/objectmodal';

interface IProps {

    propClassName: string;
    propGroup: IEPGroupAward;
    propSchoolName: string;
}


/**epApplyHome.html  【6-4-5	小隊基本資料】*/
const SimpleOpinion: React.FunctionComponent<IProps> = (props) => {
    const { propClassName, propGroup, propSchoolName} = props;
    const [opinion, setOpinion] = useState<IOpinion>();
    /**  */
    const [subTopicAfterFeedback, setSubTopicAfterFeedback] = useState<IApplyTopic[]>();
    const [searchDataList, setSearchDataList] = useState<ISearchDataSummary[]>();
    const [currentGroupStudentList, setCurrentGroupStudentList] = useState<IGroupStudent[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [openObjModal, setOpenObjModal] = useState(false);

    const loadData = async () => {
        setLoading(true);
        try {
        const groupStudentList = (await getDrStudentByGroup(propGroup.Groupno)) as IGroupStudent[];
        setCurrentGroupStudentList(groupStudentList);

        const opinion = (await getOpinionByQueryGroupNo(propGroup.Groupno)) as IOpinion;

        //const opinion = await getOpinionByQueryGroupNo(propGroup.Groupno)
        //console.log('opinion', opinion);
        setOpinion(opinion);

        const subTopic = (await getSubTopicByGroupNo(1, propGroup.Groupno)) as IApplyTopic[];
        setSubTopicAfterFeedback(subTopic);

        const rsp = (await getSubSummarySubCollectByGroupNo(1, propGroup.Groupno)) as ISearchDataSummary[];
        setSearchDataList(rsp || []);
        }
        catch (error) {
            console.log('data loading error', error);
        }
        finally {
            setLoading(false);
        }        
    }

    useEffect(() => {
        loadData();
    },[propGroup.Groupno]);
    

    /** */

    const getStart = (num: string) => {
        let number = parseInt(num);
        if (number === 5) {
            return '★★★★★';
        }
        if (number === 4) {
            return '★★★★';
        }
        if (number === 3) {
            return '★★★';
        }
        if (number === 2) {
            return '★★';
        }
        if (number === 1) {
            return '★';
        }
        return '';
    };

/*    const fetchDataOpinion = async () => {
        if (propGroupNo) {
            let opinion = (await getOpinionByGroupNo(propGroupNo)) as IOpinion[];
            setOpinion(opinion[0]);
        }
    };
    /** 取得子題資料*/
 /*   const fetchDataSubTopic = async () => {
        if (propGroupNo) {

            //取得回饋前 子題
            let subTopicbefore = (await getSubTopicByGroupNo(0, propGroupNo)) as IApplyTopic[];

            console.log('subTopicbefore ', subTopicbefore);
            setSubTopicBeforeFeedback(subTopicbefore);

            //取得回饋後子題
            let subTopicAfter = (await getSubTopicByGroupNo(1, propGroupNo)) as IApplyTopic[];

            console.log('subTopicAfter ', subTopicAfter);
            setSubTopicAfterFeedback(subTopicAfter);
        }
    };

    const fetchDataSubSummarySubCollectByGroupNo = async () => {
        if (propGroupNo) {

            let rsp = (await getSubSummarySubCollectByGroupNo(1, propGroupNo)) as ISearchDataSummary[];
            setSearchDataList(rsp || []);
            console.log("setSearchDataList ", rsp);
            // alert('JSJ'+JSON.stringify(rsp))
        }
    }; */

    const fetchDataGroupStudent = async (groupNo: string) => {
        /**取得學生資料 */
        let groupStudentList = (await getDrStudentByGroup(groupNo)) as IGroupStudent[];
        setCurrentGroupStudentList(groupStudentList);
        console.log('groupStudentList...', groupStudentList);
    };

  

    /** */
    const genRefSourcePrint = () => {

        let num = 1
        return (

            <div id="refer_source" hidden  >
                <br />
                參考資料
                {/* {JSON.stringify(props.isHidden)} */}
                <table className="text-sm table table-striped mb-0">
                    <thead>
                        <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                            <th className="p-2 text-nowrap">No</th>
                            <th className="p-2 text-nowrap">網站</th>
                            <th className="p-2 text-nowrap">網址</th>
                            <th className="p-2 text-nowrap">日期</th>
                        </tr>
                    </thead>
                    <tbody>
                        {

                            searchDataList?.map(i => i.Summaries.map(x => (
                                x.Collects.map(y => (
                                    <>
                                        <tr>
                                            <td className="p-2 text-nowrap">{num++}</td>
                                            <td className="p-2 text-nowrap">{y.Source}</td>
                                            <td className="p-2 text-nowrap">{y.Web}</td>
                                            <td className="p-2 text-nowrap">{y.SourceUpdateDate}</td>

                                        </tr>
                                    </>
                                ))
                            )))}

                    </tbody>

                </table>
            </div>)


    }

    return (
        <>
            {loading ? <div className="loading-notification">讀取資料中, 請稍後...</div> :
            
            <>
            <h4 className="text-center my-4">題目：{opinion?.Post12_DataA}</h4>

            <div className="bd-callout d-flex flex-row flex-wrap justify-content-between align-items-center px-4 py-3">
            <div className="d-flex flex-row flex-wrap">
                <span>{propGroup.SchFullName}：</span>
                <span>
                    {
                      currentGroupStudentList.map((item) => (
                        (item.StuName) ? item.StuName.trim()+'('+item.StuGrade.trim()+') ' : ''
                      ))  
                    }
                </span>
            </div>
            <div className="btn btn-outline-secondary btn-round text-nowrap mb-0 pointer" data-bs-toggle="modal"
                data-bs-target="#comment">
                成果報告檔案</div>
            </div>
            <div className="accordion" id="accordionRental">
                <div id="print-section-all">
                    <div className="accordion-item ">
                        <div className="card bd-callout bd-callout-danger">
                            <button className="accordion-button text-dark p-0" type="button" data-bs-toggle="collapse" data-bs-target="#level1" aria-expanded="true" aria-controls="level1">
                                <h4 className="my-0 me-3">階段1、決定探究題目</h4>

                                <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                            </button>                           
                        </div>
                        {/* LEVEL1 */}
                        <div className="collapse show" id="level1" data-bs-parent="#accordionRental">
                            <div className="card p-4 pt-3" id="print-section-1">
                                <h5 className="fw-bolder pt-3">1-1 提出探究題目</h5>
                                <div className="table-responsive pt-3">
                                    <table className="text-sm table table-striped mb-0">
                                        <thead>
                                            <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                                                <th className="p-2 text-nowrap">成員</th>
                                                <th className="p-2 text-nowrap">我想探究的題目</th>
                                                <th className="p-2 text-nowrap">想探究這個題目的理由</th>
                                                <th className="p-2 text-nowrap">日期</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {opinion?.Pre11.map((item) => (
                                                <tr>
                                                    <td className="text-nowrap">{item.StuName} </td>
                                                    <td>{item.DataA}</td>
                                                    <td>{item.DataB}</td>
                                                    <td> {formatDate(item.UpdateDate)}</td>
                                                </tr>
                                            ))}


                                        </tbody>
                                    </table>
                                </div>

                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">1-2 決定探究題目</h5>
                                <div className="p-3">
                                    <div className="fw-bolder text-second pb-2">
                                        我們決定探究的題目：
                                        <span className="text-primary">{opinion?.Post12_DataA} </span>
                                    </div>
                                    <div className="fw-bolder text-second pt-3 pb-2">決定原因：</div>

                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post12_DataB || '') }}></div>
                                </div>
                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">1-3 預測可能的結果</h5>
                                <div className="p-3">
                                    <div className="fw-bolder text-second pb-2">我們預測可能的結果是：</div>
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post13_DataB || '') || '' }}></div>
                                </div>

                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">1-4 困難、解決與新學習</h5>
                                <div className="p-3">
                                    <div className="fw-bolder text-second pb-2">我們在「階段1」遇到的困難：</div>
                                    <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post14_DataA + '') || '' }}></div>
                                    <div className="fw-bolder text-second pt-3 pb-2">我們最後如何解決：</div>
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post14_DataB || '') }}></div>
                                    <div className="fw-bolder text-second pt-3 pb-2">我們學到的新思維：</div>
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post14_DataC || '') }}></div>
                                </div>
                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">階段1，指導老師留言</h5>
                                <div className="p-3">
                                    <div className="fw-bolder text-second pb-2">老師留言：</div>
                                    <div dangerouslySetInnerHTML={{ __html: opinion?.Post19_DataA || '' }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="accordion-item">
                        <div className="card bd-callout bd-callout-danger">
                            <button
                                className="accordion-button text-dark collapsed p-0"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#level2"
                                aria-expanded="false"
                                aria-controls="level2"
                            >
                                <h4 className="my-0 me-2">階段2、規劃探究架構</h4>

                                <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                            </button>                          
                        </div>
                        <div id="level2" className="collapse" data-bs-parent="#accordionRental">
                            <div id="print-section-2" className="card p-4 pt-3">
                                <h5 className="fw-bolder pt-3">2-1 擬定探究子題</h5>
                                <div className="d-flex flex-wrap align-items-end">
                                    <div className="fw-bolder text-second pt-3 pb-2">
                                        本隊探究題目：
                                        <span className="text-primary">{<div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post12_DataA || '') }}></div>}</span>
                                    </div>
                                </div>
                                <div className="table-responsive pt-3">
                                    <table className="text-sm table table-striped">
                                        <thead>
                                            <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                                                <th className="p-2">#</th>
                                                <th className="p-2 text-nowrap">子題名稱</th>
                                                <th className="p-2 text-nowrap">負責隊員</th>
                                                <th className="p-2 text-nowrap">日期</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {subTopicAfterFeedback?.map((subTopic) => (
                                                <tr>
                                                    <td>{subTopic.SubNo}</td>
                                                    <td> {subTopic.SubName}</td>
                                                    <td className="text-nowrap">{subTopic.Description}</td>
                                                    <td>{formatDate(subTopic.UpdateDate)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3 print-remove">2-2 繪製探究架構</h5>
                                <div className="rounded-3 mx-auto p-3 imgborder text-center print-remove">
                                    <div className="d-flex justify-content-center align-items-start">
                                        <img
                                            className="print-remove imgs"
                                            src={`/uploads/upload${propClassName}/${opinion?.GroupNo}/${ReacherArPictureName_before}`}
                                            alt=""
                                            width="500"
                                        // onClick={() => downloadImage(`/uploads/upload${propClassName}/${opinion?.GroupNo}/${ReacherArPictureName_before}`, '研究架構.png')}
                                        />
                                    </div>                                  
                                </div>

                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">2-3 困難、解決與新學習</h5>
                                <div className="p-3">
                                    <div className="fw-bolder text-second pb-2">我們在「階段2」遇到的困難：</div>

                                    {/* pre23 */}

                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post24_DataA || '--') }}></div>
                                    <div className="fw-bolder text-second pt-3 pb-2">我們最後如何解決：</div>

                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post24_DataB || '') }}></div>
                                    <div className="fw-bolder text-second pt-3 pb-2">我們學到的新思維：</div>
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post24_DataC || '') }}></div>
                                </div>
                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">階段2，指導老師留言</h5>
                                <div className="p-3">
                                    <div className="fw-bolder text-second pb-2">老師留言：</div>
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post29_DataA || '--') }}>{/* 學校裡的新住民子女學生人數大約在70人上下 */}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <div className="card bd-callout bd-callout-danger">
                            <button
                                className="collapsed accordion-button text-dark p-0"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#level3"
                                aria-expanded="false"
                                aria-controls="level3"
                            >
                                <h4 className="my-0 me-3">階段3、蒐尋瀏覽資料</h4>
                                <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                            </button>                            
                        </div>

                        <div className="collapse" id="level3" data-bs-parent="#accordionRental">
                            <div className="card p-4 pt-3" id="print-section-3">
                                <h5 className="fw-bolder pt-3">3-1 運用關鍵字詞蒐尋</h5>
                                <div className="fw-bolder text-second pb-2">
                                    本隊探究題目：
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post12_DataA || '--') }}></div>
                                </div>
                                <div className="table-responsive pt-3">
                                    <table className="text-sm table table-striped">
                                        <thead>
                                            <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                                                <th className="p-2">#</th>
                                                <th className="p-2 text-nowrap">子題名稱</th>
                                                <th className="p-2 text-nowrap">負責隊員</th>
                                                <th className="p-2 text-nowrap">關鍵字詞</th>
                                                <th className="p-2 text-nowrap">日期</th>                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {subTopicAfterFeedback?.map((subTopic) => (
                                                <tr>
                                                    <td>{subTopic.SubNo}</td>
                                                    <td> {subTopic.SubName}</td>
                                                    <td className="text-nowrap">{subTopic.Description}</td>
                                                    <td className="text-nowrap">{subTopic.Keyword}</td>
                                                    <td>{formatDateTime(subTopic.UpdateDate)}</td>                                                  
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>

                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">3-2 蒐集瀏覽資料</h5>
                                <div className="accordion" id="searchData">
                                    <div className="p-3">
                                        {searchDataList?.map((searchData) => (
                                            <>
                                            <div className="fw-bolder text-second pt-3 pb-2">子題{searchData.SubNo},{searchData.SubName+' '}</div>
                                            <div className="table-responsive">
                                                <table className="table table-striped mb-0">
                                                    <thead>
                                                        <tr className="font-weight-bolder text-second" style={{ background: '#d9dce6' }}>
                                                            <th className="p-2 text-nowrap">重點整理</th>
                                                            <th className="p-2 text-nowrap">資料來源</th>                                                                       
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {searchData.Summaries.map((question, index) => (
                                                            <tr>
                                                                <td className="text-wrap">
                                                                    <div className="my-2" dangerouslySetInnerHTML={{ __html: replaceNewline(question.Summary || '') }}></div>
                                                                </td>
                                                                <td className="text-wrap">
                                                                    {question.Collects.map((source) => (
                                                                        <div className="my-2">
                                                                            <a href={source.Web} target="_blank" className="text-dgreen">
                                                                                {source.Source}
                                                                            </a>
                                                                            <div>可信度：{getStart(source.Credibility)}</div>
                                                                            <div>{source.Reason}</div>
                                                                        </div>
                                                                    ))}

                                                                </td>
                                                            
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            </>
                                        ))}
                                    </div>
                                </div>
                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">3-3 困難、解決與新學習</h5>
                                <div className="p-3">
                                    <div className="fw-bolder text-second pb-2">我們在「階段3」遇到的困難：</div>
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post33_DataA || '--') }}></div>

                                    <div className="fw-bolder text-second pt-3 pb-2">我們最後如何解決：</div>
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post33_DataB || '--') }}></div>

                                    <div className="fw-bolder text-second pt-3 pb-2">我們學到的新思維：</div>
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post33_DataC || '--') }}></div>
                                </div>
                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">階段3，指導老師留言</h5>
                                <div className="p-3">
                                    <div className="fw-bolder text-second pb-2">老師留言：</div>
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post39_DataA || '') }}></div>
                                    <div className="pt-3 pb-2">
                                        <span className="fw-bolder text-second">日期：</span>
                                        <span>{formatDateTime(opinion?.Post39_UpdateDate)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div className="accordion-item">
                        <div className="card bd-callout bd-callout-danger">
                            <button
                                className="collapsed accordion-button text-dark p-0"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#level4"
                                aria-expanded="false"
                                aria-controls="level4"
                            >
                                <h4 className="my-0 me-3">階段4、整合組織資料</h4>
                                <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                            </button>
                        </div>

                        <div className="collapse" id="level4" data-bs-parent="#accordionRental">
                            <div className="card p-4 pt-3" id="print-section-4">
                                <h5 className="fw-bolder pt-3">4-1 提出各子題小結</h5>
                                <div className="modal-body text-center my-3 px-0" style={{ height: '70vh', maxHeight: '530px', overflowY: 'auto' }}>
                                    <object
                                        data="https://eliteracy.twnread.org.tw/Uploads/111/pdf4_1/Step4-EA011-01新路國小.pdf"
                                        type="application/pdf"
                                        width="95%"
                                        style={{ height: '64vh', overflowX: 'auto' }}
                                    ></object>
                                </div>

                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">4-2 提出探究題目的結論</h5>
                                <div className="p-3">
                                    <div className="fw-bolder text-second pt-3 pb-2">提出結論或發現：</div>
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post42_DataB || '--') }}></div>
                                    <div className="fw-bolder text-second pt-3 pb-2">我們的結論可能的限制：</div>
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post42_DataC || '') }}></div>
                                </div>
                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">4-3 比較探究結論與先前的預測</h5>
                                <div className="p-3">
                                    <div className="fw-bolder text-second">探究前的預測：</div>
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post13_DataB || '--') }}></div>
                                </div>
                                <div className="p-3">
                                    <div className="fw-bolder text-second pt-3 pb-2">與最後的結論相比較：</div>
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post43_DataB || '--') }}></div>
                                </div>
                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">4-4 困難、解決與新學習</h5>
                                <div className="p-3">
                                    <div className="fw-bolder text-second pt-3 pb-2">我們在「階段4」遇到的困難：</div>
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post44_DataA || '') }}></div>

                                    <div className="fw-bolder text-second pt-3 pb-2">我們最後如何解決：</div>
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post44_DataB || '') }}></div>

                                    <div className="fw-bolder text-second pt-3 pb-2">我們學到的新思維：</div>
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post44_DataC || '') }}></div>
                                    {/* <div>1.做任何事都要有行動力，尤其是遇到大家意見不一樣的時候，必須有"實驗行動 "能力，找出最佳答案。</div>
                                    <div>2.做事前須有萬全的準備，而且以後除了要細看文字說明外，還必須要從頭到尾閱讀，這樣計畫才能周延，做事也才能事半功倍。</div> */}

                                </div>

                                <hr className="horizontal dark" />
                                <h5 className="fw-bolder pt-3">階段4，指導老師留言</h5>
                                <div className="p-3">
                                    <div className="fw-bolder text-second pt-3 pb-2">老師留言：</div>
                                    <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post49_DataA || '') }}></div>
                                    <div className="pt-3 pb-2">
                                        <span className="fw-bolder text-second">日期：</span>
                                        <span>{formatDateTime(opinion?.Post49_UpdateDate)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <div className="card bd-callout bd-callout-danger">
                            <button
                                className="collapsed accordion-button text-dark p-0"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#level5"
                                aria-expanded="false"
                                aria-controls="level5"
                            >
                                <h4 className="my-0 me-2">成果報告</h4>
                                <i className="collapse-close fas fa-angle-down pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                                <i className="collapse-open fas fa-angle-up pt-1 position-absolute end-0 fs-22" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div className="collapse" id="level5" data-bs-parent="#accordionRental">
                            <div className="card p-4 pt-3">
                                <div id="print-section-5">
                                    <h5 className="fw-bolder pt-3">學生省思</h5>
                                    <label className="fw-bolder text-second">省思</label>
                                    <div className="pb-3" dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post54_DataA || '--') }}></div>
                                    <hr className="horizontal dark" />

                                    <h5 className="fw-bolder pt-3">
                                        指導老師心得<span className="fs-16">(111年度新增)</span>
                                    </h5>
                                    <div className="p-3">
                                        <div className="fw-bolder text-second pt-3 pb-2">老師心得：</div>
                                        <div dangerouslySetInnerHTML={{ __html: replaceNewline(opinion?.Post55_DataA || '--') }}></div>
                                    </div>
                                </div>
                                <hr className="horizontal dark" />

                                <h5 className="fw-bolder pt-3">成果報告</h5>
                                <div className="mb-3">
                                    
                                </div>
                             
                            </div>
                        </div>
                    </div>
                   
                </div>

            </div>
             <ObjectModal title="實施計畫" isOpen={openObjModal} setOpenModal={setOpenObjModal} objectURL={"./uploads/old-uploads/"}/>  
            </>    
        }       




        </>
    );
};
export default SimpleOpinion;
