import { type } from 'os'
import React, { useEffect, useState } from 'react'
import { PTeamModal } from '../../components/modal/pteammodal'
import { IEPGroup, IEPGroupType } from '../../interfaces/EPGroup'
import IPageProps from '../../interfaces/page'
import { handleLink } from '../../modules/utils'
import { getEPGroup, getEPGroupByYr, getEPGroupType } from '../../services/EPDataService'
import FooterPage from '../footer'
import TopNavPage1 from '../topNav1'
import TopNavPage2 from '../topNav2'


const EpResultPage: React.FunctionComponent<IPageProps> = props => {
    
    const grpArray:IEPGroupType[]= [
        {Year:'112年度',GroupType:['國小A組','國小B組','國中C組','國中D組']},
        {Year:'111年度',GroupType:['國小A組','國小B組','國中C組','國中D組']},
        {Year:'110年度',GroupType:['國小A組','國小B組','國中C組','國中D組']},
        {Year:'109年度',GroupType:['國小組','國中組']},
        {Year:'108年臺北市',GroupType:['國小組','國中組']},
        {Year:'108年度',GroupType:['國小組','國中組']}]
    
   const [curYear,setCurYear]=useState(0); 


    const updGroupTbl=(drp:IEPGroup[])=>{
        let innerBody=document.getElementById('modal-title');
        if(innerBody!==null) innerBody.innerHTML=`${grpArray[curYear].Year}全國聯賽<span class="ms-2">參賽隊伍題目</span>`;

        // 變更參賽隊伍題目 modal 內容
        let innerBody1=document.getElementById('modal-detail');
        if(innerBody1!==null) 
        {
            innerBody1.innerHTML=``
            if(drp!==undefined) {
                grpArray[curYear].GroupType.forEach((item,indi)=>{
                    const curYrGrp=drp.filter((item1)=>item1.Type.trim()===item);
                    let tRow='';
                    curYrGrp.forEach((item1,indi1)=>{
                        tRow+=`
                            <tr>
                            <td class="p-2">${item1.Groupno}</td>
                            <td class="p-2">${item1.Groupname}</td>
                            <td class="p-2">${item1.City}</td>
                            <td class="p-2">${item1.Schoolname}</td>
                            <td class="p-2">${item1.DataA}</td>
                            </tr>`
                    })
                    innerBody1!.innerHTML+=`
                        <div class="card p-2 mx-3 my-4">
                            <h5 class="mt-3">${item}</h5>
                            <div class="table-responsive text-start">
                                <table class="text-sm table table-striped">
                                <thead>
                                    <tr class="font-weight-bolder text-nowrap" style="color: #5c71a2; background: #d9dce6;">
                                    <th class="p-2">小隊編號</th>
                                    <th class="p-2">小隊名稱</th>
                                    <th class="p-2">縣市</th>
                                    <th class="p-2">學校</th>
                                    <th class="p-2">題目</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    ${tRow}
                                </tbody>
                                </table>
                            </div>
                        </div>
                        `
                })
            }
        }
    }

    useEffect(()=>{
        getEPGroupByYr(grpArray[curYear].Year).then((res)=>{
            updGroupTbl(res);
        })
    },[curYear])

    return (
        <>
            <TopNavPage2/>
            <div className="section pt-3">
                <div className="container px-3 px-md-4">
                    <div className="d-flex align-items-center justify-content-between flex-column flex-md-row">
                        <h5 className="title mb-3 me-3">線上探究<small className="ms-2">EP,Explore Process</small></h5>
                        <h5 className="order-2 order-md-1 mb-3">歷年成果</h5>
                        <div className="order-1 order-md-2 mb-3 text-center text-md-end" style={{width: '232px'}}>
                            <button type="button" className="btn btn-outline-dark mb-0" onClick={()=>handleLink('/eptest')}>
                                <i className="fas fa-chevron-left"></i>
                                返回 EP 線上探究
                            </button>
                        </div>
                    </div>                

                    <div className="mt-4"></div>
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">                            
                            <div className="card level-h">
                                <div className="card-header text-center">
                                    <div className="text-dblue fw-bold">全國聯合競賽</div>
                                    <h3 className="text-dblue fw-bold mt-2">112年度</h3>
                                </div>
                                <hr className="horizontal dark mt-0"/>
                                <div className="card-body pt-0">
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">實施計畫</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0" onClick={()=>setCurYear(0)}>
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#team111">參賽隊伍題目</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">參賽數據</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">獲獎隊伍及發表片段</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">評審講評</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">活動照片</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">報導者特別獎</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">媒體報導</figcaption>
                                    </figure>
                                </div>
                            </div>    
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">                            
                            <div className="card level-h">
                                <div className="card-header text-center">
                                    <div className="text-dblue fw-bold">全國聯合競賽</div>
                                    <h3 className="text-dblue fw-bold mt-2">111年度</h3>
                                </div>
                                <hr className="horizontal dark mt-0"/>
                                <div className="card-body pt-0">
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">實施計畫</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0" onClick={()=>setCurYear(1)}>
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#team111">參賽隊伍題目</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">參賽數據</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">獲獎隊伍及發表片段</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">評審講評</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">活動照片</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">報導者特別獎</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">媒體報導</figcaption>
                                    </figure>
                                </div>
                            </div>    
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                            <div className="card level-h">
                                <div className="card-header text-center">
                                    <div className="text-dblue fw-bold">全國聯合競賽</div>
                                    <h3 className="text-dblue fw-bold mt-2">110年度</h3>
                                </div>
                                <hr className="horizontal dark mt-0"/>
                                <div className="card-body pt-0">
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">實施計畫</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0" onClick={()=>setCurYear(2)}>
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#team111">參賽隊伍題目</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">參賽數據</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">獲獎隊伍及發表片段</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">評審講評</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">活動照片</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">報導者特別獎</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">媒體報導</figcaption>
                                    </figure>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                            <div className="card level-h">
                                <div className="card-header text-center">
                                    <div className="text-dblue fw-bold">全國聯合競賽</div>
                                    <h3 className="text-dblue fw-bold mt-2">109年度</h3>
                                </div>
                                <hr className="horizontal dark mt-0"/>
                                <div className="card-body pt-0">
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">實施計畫</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0" onClick={()=>setCurYear(3)}>
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#team111">參賽隊伍題目</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">參賽數據</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">獲獎隊伍及發表片段</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">評審講評</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">活動照片</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">報導者特別獎</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">媒體報導</figcaption>
                                    </figure>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                            <div className="card level-h">
                                <div className="card-header text-center">
                                    <div className="text-dblue fw-bold">臺北市競賽</div>
                                    <h3 className="text-dblue fw-bold mt-2">108年度</h3>
                                </div>
                                   
                                <hr className="horizontal dark mt-0"/>
                                <div className="card-body pt-0">
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">實施計畫</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0" onClick={()=>setCurYear(4)}>
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#team111">參賽隊伍題目</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">參賽數據</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">獲獎隊伍及發表片段</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">評審講評</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">活動照片</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">報導者特別獎</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">媒體報導</figcaption>
                                    </figure>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                            <div className="card level-h">
                                <div className="card-header text-center">
                                    <div className="text-dblue fw-bold">臺中市競賽</div>
                                    <h3 className="text-dblue fw-bold mt-2">108年度</h3>
                                </div>
                                <hr className="horizontal dark mt-0"/>
                                <div className="card-body pt-0">
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">實施計畫</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0" onClick={()=>setCurYear(5)}>
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#team111">參賽隊伍題目</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">參賽數據</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">獲獎隊伍及發表片段</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">評審講評</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">活動照片</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">報導者特別獎</figcaption>
                                    </figure>
                                    <figure className="p-2 m-0">
                                        <figcaption className="blockquote-footer text-second m-0 pointer" data-bs-toggle="modal"
                                        data-bs-target="#plan111">媒體報導</figcaption>
                                    </figure>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>


            </div>
            {/* 參賽隊伍題目 */}            
            <div className="modal fade" id="team111" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog max-width-1200 modal-dialog-centered" role="document">
                    <div className="modal-content mx-3">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modal-title"></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" className="text-dark" style={{fontSize: '36px', lineHeight:'12px'}}>&times;</span>
                            </button>
                        </div>
                            <div className="modal-body text-center pb-3 px-0" style={{height:'70vh',maxHeight: '530px', overflowY:'auto'}}>
                            <div id='modal-detail'></div>
                        </div>
                    </div>
                </div>
            </div>            
            <FooterPage/>

        </>
        
    );
}

export default EpResultPage;