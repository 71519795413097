import axios, { AxiosError } from 'axios'
import { useState } from 'react';
import  config from '../config/config';
import { IEPGroup, IEPGroupType } from '../interfaces/EPGroup';
import  {IEPSchedule} from '../interfaces/EPSchedule';


export const getEPScedule=()=>{
    const url=config.server.url+`/api/DrSchedule/yr/112年度`

    return axios.get(url).then((res)=>{
        const rest:IEPSchedule[]=res.data;
        console.log(rest);
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getEPGroup=(yr:string,type:string)=>{
    const url=config.server.url+`/api/DrGroup/yr/${yr}/type/${type}`

    return axios.get(url).then((res)=>{
        const rest:IEPGroup[]=res.data;
        console.log(rest);
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getEPGroupByYr=(yr:string)=>{
    //const url=config.server.url+`/api/DrGroup/yr/${yr}`
    const url=config.server.url+`/api/DrGroup/info?class=${yr}`
    return axios.get(url).then((res)=>{
        const rest:IEPGroup[]=res.data;
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

// 建立年度參賽隊伍資料,以小組分類
export const getEPGroupType=(grp:IEPGroupType):IEPGroup[][]=>{
    let result:IEPGroup[][]=[[]];
    let temp:IEPGroup[];
    getEPGroupByYr(grp.Year).then((res)=>{
        temp=res;
        grp.GroupType.forEach((item,idx)=>{
            const filter=temp.filter((titem)=>titem.Type===item);
            console.log('filter len:'+filter.length);
            result[idx]=filter;
            console.log('result idx:'+idx)
            console.log(result[idx]);
            console.log('result all:')
            console.log(result);
            //result.push(res.filter((titem)=>titem.Type===item));

        });
        console.log('in process1:')
        console.log(result);
        return result;        
    });
    console.log('in process:'+result);
    return result;
}

        










