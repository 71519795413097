import React, { useContext, useEffect, useState } from 'react';
import { BSModal } from '../../components/modal/bsmodal';
import { IExSchool } from '../../interfaces/ExSchool';
import IPageProps from '../../interfaces/page';
import { handleLink } from '../../modules/utils';
import { getExSchoolAreaByCity, getExSchoolAreaByCityArea, getExSchoolCities } from '../../services/ExSchoolService';
import { createExTeacher, getExTeacherByTNo, getMaxTID } from '../../services/ExStuTeService';
import FooterPage from '.././footer';
import TopNavPage1 from '../topNav1';
import { getEPApplyByNo } from '../../services/EP/EpLoginService';
import { IInsertApply } from '../../interfaces/EpModal';
import { insertDrApplyByPrefix } from '../../services/EP/EpNewApplyService';
import { MyContext } from '../home';
import { getAuthData } from './tool/helper';

const EpNewApplyPage: React.FunctionComponent<IPageProps> = (props) => {
    const [selSchool, setSelSchool] = useState('');
    const [inpTeacherName, setInpTeacherName] = useState('');
    const [inpTeacherNo, setInpTeacherNo] = useState('');
    const [inpPwd, setInpPwd] = useState('');
    const [inpPwdAgain, setInpPwdAgain] = useState('');
    const [inpCell, setInpCell] = useState('');
    const [inpEmail, setInpEmail] = useState('');
    const [pressed, setPressed] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    // const [openModal,setOpenModal]=useState(false);
    const [openErrModal, setOpenErrModal] = useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [maxTid, setMaxTid] = useState(0);
    const [prefix, setPrefix] = useState('G'); //113年前贅詞
    const [groupChar, setGroupChar] = useState<'A' | 'B' | 'C' | 'D'>();
    const [successApplyNo,setSuccessApplyNo] =useState('')
    const [formData, setFormData] = useState<IInsertApply>({
        applyName: '',
        mobile: '',
        email: '',
        password: '',
        type: '',
        classnum: 0,
        /** 公私立 */
        pubPr: '',
        /** 國中 高中 */
        schoolType: '',
        passwordChecked: ''
    });



    useEffect( () => {
        
        loadCtrl(sessionStorage.getItem('role')||'guest')
    }, []);


    const loadCtrl = async (role: string) => {
        alert(role)
        let ctrlInfo = await  getAuthData(role) ;
        context?.setCtrlInfoList(ctrlInfo);
    };



    const context = useContext(MyContext);
    const getCtrl = (CtrlName: string) => {
        let result;
        if(context?.crtlInfoList?.length){
            result = context?.crtlInfoList?.find((x) => {
                return x.Control.trim() === CtrlName.trim();
            });


        }
        context?.setCurrentRole(result)
 
        return result;
    };

    /** 當設定畫面有改變時*/
    const setFormDataOnChange = (e: React.ChangeEvent<HTMLInputElement> | any, attr: string, value?: string) => {
        setFormData({ ...formData, [attr]: e.target.value } as IInsertApply);
    };

    /** */
    const insertData = async () => {
        let errMsg = validate();
        let groupChar = await groupCharSet();
        try {
            if (!errMsg) {
                // alert('insertdata ...' + groupChar);

                let params = {
                    applyName: formData.applyName.trim(),
                    mobile: formData.mobile.trim(),
                    email: formData.email.trim(),
                    password: formData.password.trim(),
                    type: formData.schoolType + groupChar + '組',
                    classnum :formData.classnum 
                } as IInsertApply;
                // alert('send....' + JSON.stringify(params));
                let teacherNum =await  insertDrApplyByPrefix(params, prefix + groupChar) as any;
                setSuccessApplyNo (teacherNum[0].ApplyNo)
                // alert('新增成功!' + JSON.stringify(teacherNum));
                setOpenSuccessModal(true )
                setFormData({
                  applyName: '',
                  mobile: '',
                  email: '',
                  password: '',
                  type: '',
                  classnum: 0,
                  /** 公私立 */
                  pubPr: '',
                  /** 國中 高中 */
                  schoolType: '',
                  passwordChecked: ''
                
              });
                // handleLink('/eplogin');
            }
        } catch (ex) {
            alert('新增承辦人發生錯誤:' + JSON.stringify(ex));
        }
    };

    /** 設定 組別*/
    const groupCharSet = async () => {
        if (formData.classnum && formData.schoolType && formData.pubPr) {
            let groupChar: 'A' | 'B' | 'C' | 'D';

            if (formData.classnum <= 30 && formData.schoolType == '國小' && formData.pubPr == '公立') {
                groupChar = 'A';
            } else if (formData.classnum >= 31 && formData.schoolType == '國小' && formData.pubPr == '私立') {
                groupChar = 'B';
            } else if (formData.classnum <= 30 && formData.schoolType == '國中' && formData.pubPr == '公立') {
                groupChar = 'C';
            } 
            else {
                groupChar = 'D';
            }

            setGroupChar(groupChar);
            setFormData({ ...formData, type: formData.schoolType + groupChar + '組' } as IInsertApply);
            return groupChar;
        }
    };

    // 驗證 validate-ref
    const validate = () => {
        let noError = true;
        let errMsg = '';

        if (formData?.applyName.trim() === '') {
            noError = false;
            errMsg = errMsg + '承辦人姓名不可空白,';
        }
        if (formData?.applyName.trim().length > 10) {
            noError = false;
            errMsg = errMsg + '承辦人姓名不可超過10碼,';
        }

        // 檢查手機
        if (formData?.mobile.trim() === '') {
            noError = false;
            errMsg = errMsg + '手機不可空白,';
        }

        //檢查密碼
        if (!formData?.password || formData?.password?.trim() === '') {
            noError = false;
            errMsg = errMsg + '密碼不可空白,';
        } else {
            //   alert(formData?.password)
            const regChk: RegExp = /^([a-zA-Z0-9]){5,8}/;
            if (!regChk.test(formData.password.trim())) {
                noError = false;
                errMsg = errMsg + '密碼設定錯誤(5~8碼，英文或數字), ';
            }
            if (formData.password.trim() !== formData.passwordChecked.trim()) {
                noError = false;
                errMsg = errMsg + '密碼不一致 ,';
            }
        }

        //檢查email
        if (formData.email.trim() === '') {
            noError = false;
            errMsg = errMsg + 'Email不可空白,';
        }
        if (formData.email.trim() !== '') {
            const emailChk: RegExp = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
            if (!emailChk.test(formData.email.trim())) {
                noError = false;
                errMsg = errMsg + 'Email格式錯誤,';
            }
        }

        //班級數
        if (!formData.classnum || formData.classnum === 0) {
            noError = false;
            errMsg = errMsg + '班級數不可為0,';
        }

        // 國中 國小
        if (!formData.schoolType || formData.schoolType.trim() === '') {
            noError = false;
            errMsg = errMsg + '國中國小未填,';
        }
        // 國中 國小
        if (!formData.schoolType || formData.schoolType.trim() === '') {
            noError = false;
            errMsg = errMsg + '國中國小未填,';
        }
        //公立 私立
        if (!formData.pubPr || formData.pubPr.trim() === '') {
            noError = false;
            errMsg = errMsg + '公私立未填,';
        }

        if (noError) {
       
        } else {
            errMsg = errMsg.substring(0, errMsg.length - 1) + '。';
            setErrMsg(errMsg);
            setOpenErrModal(true);
        }
        return errMsg;
    };

    useEffect(() => {}, []);

    useEffect(() => {
        if (pressed) {
        }
    }, [pressed]);
    return (
        <>
   
            {/* 標頭 */}
            <TopNavPage1 />
         {getCtrl('承辦人申請')?<>  <div className="py-5">
                <div className="container" style={{ maxWidth: '520px' }}>
                    <div className="row" style={{ borderRadius: '8px', boxShadow: '0px 0px 10px 5px #d7d7d7' }}>
                        <div className="col-12 bg-primary logreg login px-4">
                            <h4 className="py-3 text-center text-white fw-bolder">承辦人申請編號</h4>

                            <div className="text-white my-4">
                         
                                <div className="d-inline ">
                                    <div className="ps-2">
                                        <span> 請選擇學校類型 : </span>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="epschool-type"
                                                id="epunders-1"
                                                checked={formData.schoolType === '國中'}
                                                value={'國中'}
                                                onChange={(e) => setFormDataOnChange(e, 'schoolType')}
                                            />
                                            <label className="custom-control-label text-white fw-normal" htmlFor="epunders-1">
                                                國中
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="epschool-type"
                                                id="epunders-2"
                                                checked={formData.schoolType === '國小'}
                                                value={'國小'}
                                                onChange={(e) => setFormDataOnChange(e, 'schoolType')}
                                            />
                                            <label className="custom-control-label text-white fw-normal" htmlFor="epunders-2">
                                                國小
                                            </label>
                                        </div>
                                    </div>

                                    <div className="ps-2">
                                        <span>學校是公立/私立 : </span>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="epschoolPuPr"
                                                id="epschoolPuPr-1"
                                                checked={formData.pubPr === '公立'}
                                                value={'公立'}
                                                onChange={(e) => setFormDataOnChange(e, 'pubPr')}
                                            />
                                            <label className="custom-control-label text-white fw-normal" htmlFor="epschoolPuPr-1">
                                                公立
                                            </label>
                                        </div>
                                        <div className="form-check-inline form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="epschoolPuPr"
                                                id="epschoolPuPr-2"
                                                checked={formData.pubPr === '私立'}
                                                value={'私立'}
                                                onChange={(e) => setFormDataOnChange(e, 'pubPr')}
                                            />
                                            <label className="custom-control-label text-white fw-normal" htmlFor="epschoolPuPr-2">
                                                私立
                                            </label>
                                        </div>
                                    </div>
                                    <div className=" form-group  row mt-3 ">
                                        <label className="col-2" htmlFor="classNum">
                                            班級數
                                        </label>
                                        <input 
                                        defaultValue={""}
                                            value={formData.classnum} 
                                            min="1"
                                            max ="100"
                                            onChange={(e) => setFormDataOnChange(e, 'classnum')}
                                            type="number"
                                            className="col col-4 mx-2 form-control"
                                            placeholder="班級數"
                                            id="classNum"
                                            style={{ width: '25%' }}
                                        />
                                        {/* <span className='inline'>以當學年度普通班班級數計算</span> */}
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <input value={formData.applyName} onChange={(e) => setFormDataOnChange(e, 'applyName')} type="text" className="col mx-2 form-control" placeholder="承辦人姓名" />
                                    <input value={formData.mobile} onChange={(e) => setFormDataOnChange(e, 'mobile')} type="text" className="col mx-2 form-control" placeholder="承辦人手機號碼" />
                                </div>

                                <div className="row mt-3">
                                    <input
                                        value={formData.password}
                                        onChange={(e) => setFormDataOnChange(e, 'password')}
                                        type="password"
                                        className="col mx-2 form-control"
                                        placeholder="密碼(5~8碼，英文或數字)"
                                    />
                                    <input
                                        value={formData.passwordChecked}
                                        onChange={(e) => setFormDataOnChange(e, 'passwordChecked')}
                                        type="password"
                                        className="col mx-2 form-control"
                                        placeholder="確認密碼"
                                    />
                                </div>

                                <div className="row mt-3">
                                    <input value={formData.email} onChange={(e) => setFormDataOnChange(e, 'email')} type="text" className="col mx-2 form-control" placeholder="承辦人電子郵件" />
                                </div>
                                <div className="text-center mt-4">
                                    <button className="btn btn-round btn-white text-primary mb-0" onClick={() => insertData()}>
                                        送出申請
                                    </button>
                                </div>
                                <div className="text-center my-5">
                                    <a href={'/eplogin'} className="text-white" style={{ borderBottom: '1px solid #fff' }}>
                                        返回EP登入
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <BSModal title={'註冊成功'} isOpen={openSuccessModal} setOpenModal={setOpenSuccessModal}>
          
                您的帳號為 :  {successApplyNo} 
              <br/>
                請記住帳號並由( <u><a href={'/eplogin'} >返回EP登入   </a></u> )登入
            </BSModal>
            <BSModal title={'欄位輸入錯誤'} isOpen={openErrModal} setOpenModal={setOpenErrModal}>
                {errMsg}
            </BSModal>
            <div w3-include-html="footer.html"></div></>:<div>目前未開放</div>}
          
        </>
    );
};

export default EpNewApplyPage;
