import axios, { AxiosError } from 'axios';
import config from '../../config/config';
import { IInsertApplyGroup, IInsertApplyStudent, IUpdateApplyGroup, IUpdateApplyStudent } from '../../interfaces/EpModal';

// 取得所有獲獎小組資料
// Get DrGroup All Award Data
// Get /api/DrGroup/award
export const getDrGroupAward = async () => {
    const url = config.server.url + '/api/DrGroup/award';

    return new Promise((resolve, reject) => {
        axios
            .get(url)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                reject(error); //
            });
    });
};

// 以年度及申請編號選取參賽小組基本資料
// Get DrGroup Prime Data by ApplyNo
// Get /api/DrGroup/prim/yr/:yr/applyno/:applyno
// const getDrGroupPrimeByYrApplyNo = asyncHandler(async(req: Request, res: Response) => {
export const getDrGroupPrimeByYrApplyNo = async (year: string, applyno: string) => {

    const url = config.server.url + `/api/DrGroup/prim/yr/${year}/applyno/${applyno}`;

    return new Promise((resolve, reject) => {
        axios
            .get(url)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                reject(error); //
            });
    });
};

// 以參賽隊伍編號取得參賽學生資料
// Get DrStudent by GroupNo
// Get /api/DrStudent/groupno/:groupno
//const getDrStudentByGroup = asyncHandler(async(req: Request, res: Response) => {
export const getDrStudentByGroup = async (groupno: string) => {
    const url = config.server.url + `/api/DrStudent/groupno/${groupno}`;

    return new Promise((resolve, reject) => {
        axios
            .get(url)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                reject(error); //
            });
    });
};

// 以年度及申請編號新增參賽小組 小組編號自動由最後一筆後2碼加1
// Create New DrGroup by year and applyno
// Post /api/DrGroup/new/yr/:yr/applyno/:applyno
// const createDrGroupByYrApplyNo = asyncHandler(async(req: Request, res: Response) => {
export const createDrGroupByYrApplyNo = async (param: IInsertApplyGroup, year: string, applyNo: string) => {

    const url = config.server.url + `/api/DrGroup/new/yr/${year}/applyno/${applyNo}`;
    
    return new Promise((resolve, reject) => {
        axios
            .post(url, param)
            .then((response) => {
                console.log('createDrGroupByYrApplyNo ', response.data);
                resolve(response.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                reject(error); //
            });
    });
};

// Post /api/DrStudent/newapply/groupno/:groupno
// Response Body
/*
{
  "StuNo":"FD001-01-5"
}
*/
// Request URL Example http://localhost:1448/api/DrStudent/newapply/groupno/FD001-01
// const createDrStudentByGroup = asyncHandler(async(req: Request, res


export const createDrStudentByGroup = async (param: IInsertApplyStudent, groupno: string) => {
  const url = config.server.url + `/api/DrStudent/newapply/groupno/${groupno}`;
  return new Promise((resolve, reject) => {
      axios
          .post(url, param)
          .then((response) => {
              console.log('getLoginInfoByAccountAndPass ', response.data);
              resolve(response.data);
          })
          .catch((error) => {
              console.error('Error fetching data:', error);
              reject(error); //
          });
  });
};


// Delete DrGroup by year and groupno
// Delete /api/DrGroup/delete/yr/:yr/groupno/:groupno
// const deleteDrGroupByYrGroupNo = asyncHandler(async(req: Request, res: Response) => {
export const deleteDrGroupByYrGroupNo = async (year: string, groupno: string) => {
    const url = config.server.url + `/api/DrGroup/delete/yr/${year}/groupno/${groupno}`;

    // return alert('url :'+url)
    return new Promise((resolve, reject) => {
        axios
            .delete(url)
            .then((response) => {
                console.log('getLoginInfoByAccountAndPass ', response.data);
                resolve(response.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                reject(error); //
            });
    });
};

// Update DrGroup by groupno
// Put /api/DrGroup/updprim/yr/:yr/groupno/:groupno
// const updateDrGroupPrimeByYrGroupNo = asyncHandler(async(req: Request, res: Response) => {

export const updateDrGroupPrimeByYrGroupNo = async (param: IUpdateApplyGroup, year: string, groupNo: string) => {
    const url = config.server.url + `/api/DrGroup/updprime/yr/${year}/groupno/${groupNo}`;
    return new Promise((resolve, reject) => {
        axios
            .put(url, param)
            .then((response) => {
                console.log('getLoginInfoByAccountAndPass ', response.data);
                resolve(response.data);
            })
            .catch((error) => {
                alert('err');
                console.error('Error fetching data:', error);
                reject(error); //
            });
    });
};

// 以學生申請編號更新學生資料
// Update DrStudent by StuNo
// PUT /api/DrStudent/updno/:updno
// const updateDrStudentByNo = asyncHandler(async(req: Request, res: Response) => {
//   try{
//     await pool.connect();
//     const request=new sql.Request(pool);
//     const newApply:{
//       groupno:string,
//       stuname:string,
//       stugrade:string,
//       password:string,
//       compare:string,
//       remark:string,
//     }=req.body;

export const updateDrStudentByNo = async (param: IUpdateApplyStudent, studentNo: string) => {
    const url = config.server.url + `/api/DrStudent/updno/${studentNo}`;
    return new Promise((resolve, reject) => {
        axios
            .put(url, param)
            .then((response) => {
                console.log('getLoginInfoByAccountAndPass ', response.data);
                resolve(response.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                reject(error); //
            });
    });
};


// Jean
  // 以小隊編號及意見編碼取得該小隊的意見資料,OpIdx對照表參見 OpinionCode (含回饋前及回饋後) 回饋前 isfeedback=0 ,回饋後 isfeedback=1
  // Get Opinion or OpinionSure by groupno and opidx
  // Get /api/OpinionTable/isfeedback/:isfeedback/opidx/:opidx/groupno/:groupno 
  export const getOpinionByGroupOpIdx0000 = async  (isfeedback :0|1, opidx :string ,groupno:string) =>{
    const url=config.server.url+`/api/OpinionTable/isfeedback/${isfeedback}/opidx/${opidx}/groupno/${groupno}`
    console.log("getDrGroupByTeaNo url",url) ;
        return new Promise((resolve, reject) => {
          axios.get(url)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              console.error('Error fetching data:', error);
              reject(error); //
            });
        });
  
  
  }

  // 以參賽小組編號查詢指導老師資料
// Get DrTeaGroup by groupno
// Get /api/DrGroup/teagroup/groupno/:groupno
// const getDrTeaGroupByGroupNo = asyncHandler(async(req: Request, res: Response) => {

export const getDrTeaGroupByGroupNo = async  (groupno:string) =>{
  const url=config.server.url+`/api/DrGroup/teagroup/groupno/${groupno}`
  console.log("getDrTeaGroupByGroupNo url",url) ;
      return new Promise((resolve, reject) => {
        axios.get(url)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            reject(error); //
          });
      });


}



// 以教師編號及參賽小組編號新增指導之參賽小組資料
// Add DrTeaGroup by TeaNo and GroupNo
// Post t createDrTeaGroupByTeaNoGroup 
// Response Body
/*
{
  "TeaNo": "FD001-B",
}
*/

  
export interface newApply{
  groupno:string,
  teano:string,
}
// 以教師編號及參賽小組編號新增指導之參賽小組資料
// Add DrTeaGroup by TeaNo and GroupNo
// Post /api/DrTeacher/newteagroup
// Response Body
/*
{
  "TeaNo": "FD001-B",
}
*/
// 以教師編號及參賽小組編號新增指導之參賽小組資料
// Add DrTeaGroup by TeaNo and GroupNo
// Post /api/DrTeacher/newteagroup
// Response Body
/*
{
  "TeaNo": "FD001-B",
}
*/


export const createDrTeaGroupByTeaNoGroup = async (param: { groupno:string, teano:string}) => {

  const url = config.server.url + `/api/DrTeacher/newteagroup`;
  return new Promise((resolve, reject) => {
      axios
          .post(url, param)
          .then((response) => {
              console.log('getLoginInfoByAccountAndPass ', response.data);
              resolve(response.data);
          })
          .catch((error) => {
              console.error('Error fetching data:', error);
              reject(error); //
          });
  });
};





// Jean
// 以參賽小組編號(uid)刪除參賽小組
// Delete DrGroup by uid
// Delete /api/DrGroup/teagroup/delete/groupno/:groupno
// const deleteDrTeaGroupByGroupNo = asyncHandler(async(req: Request, res: Response) => {

  export const deleteDrTeaGroupByGroupNo = async ( groupno:string   )=>{
    //Here
    console.log("deleteSubTopicByGroupNoSubNo",groupno);
    const url=config.server.url+`/api/DrGroup/teagroup/delete/groupno/${groupno}`
    console.log('url',url)
    return new Promise((resolve, reject) => {
        axios.put(url)
          .then(response => {
            console.log("updateOpinionByGroupOpIdxUID ",response.data);
            resolve(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            reject(error); //
          });
      });


   

}


// 以學生紀錄編號刪除學生資料
// Delete DrStudent by uid
// Get /api/DrStudent/delete/uid/:uid
// const delDrStudentByUid= asyncHandler(async(req: Request, res: Response) => {
  export const delDrStudentByUid = async (uid:string ) => {
    const url = config.server.url + `/api/DrStudent/delete/uid/${uid}`;


    // return alert('url :'+url)
    return new Promise((resolve, reject) => {
        axios
            .delete(url)
            .then((response) => {
                console.log('getLoginInfoByAccountAndPass ', response.data);
                resolve(response.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                reject(error); //
            });
    });
};
