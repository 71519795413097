const config={
   server2:{
    url:'http://read-dev.ischool.com.tw:1448'
   },
   Server1:{
     url:'http://localhost:1448'
     },
   server4:{
      url:'http://20.187.67.140:1448'
     },
   server3:{
      url:'http://api.twnread.org.tw'
     },   
   server:{
      url:'https://api.twnread.org.tw'
     },
   FerrisURL:[
   [   'Maten1_0.png','Maten1_1.png','Maten1_2.png','Maten1_3.png','Maten1_4.png','Maten1_5.png','Maten1_6.png'
   ],
   [
      'Maten4_0.png','Maten4_1.png','Maten4_2.png','Maten4_3.png','Maten4_4.png','Maten4_5.png','Maten4_6.png'
   ],
   [
      'Maten5_0.png','Maten5_1.png','Maten5_2.png','Maten5_3.png','Maten5_4.png','Maten5_5.png','Maten5_6.png'
   ],
   [
      'Maten6_0.png','Maten6_1.png','Maten6_2.png','Maten6_3.png','Maten6_4.png','Maten6_5.png','Maten6_6.png'
   ],
   [
      'MatenDP_0.png','MatenDP_1.png','MatenDP_2.png','MatenDP_3.png','MatenDP_4.png','MatenDP_5.png','MatenDP_6.png'
   ]],
   ppFerrisPrefix: './assets/img/',
   oAuth:{
      clientID: '311e9144d62d4571ad42e819ca2ca68a',
      clientSecret: '52801e9aefa4467a9040d6824debae6b4f566b0d3a9a463285de3f8d9f453e3f',
      redirectURL: 'https://localhost:3000/auth/callback.ashx',
      redirectURL_google:'https://eliteracy.twnread.org.tw/oauth/callback_google.ashx',
      authHost: 'https://auth.ischool.com.tw',
      ptsHost:'https://pts.ntpc.edu.tw'
   },
   localAuth:{
      signgooglein:'/auth/signin-google',
      signntpcin:'/auth/signin-ntpc',
   }
}

export default config;