import React, { createContext, useContext, useEffect, useState } from 'react';
// import IPageProps from '../interfaces/page'
import NewsCards from './news/newscards';
import TopNavPage1 from './topNav1';
import MainFuncPage from './mainFunc';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import PPTestPage from './pptest';
import PPTestPage1 from './pptest/pptest1';
import FooterPage from './footer';
import PPTestPage2 from './pptest/pptest2';
import PPTestPage3 from './pptest/pptest3';
import PPTestPage4 from './pptest/pptest4';
import DPTestPage from './dptest';

import DPPracticePageWeb from './dptest/dppractice/dppractice-web';
import DPPracticePageMix from './dptest/dppractice/dppractice-mix';
import DPPracticePage from './dptest/dppractice/dppractice';
import DPRefPage from './dptest/dpref';
import LoginPage from './login/login';
import NewStudentPage from './newstudent';
import NewTeacherPage from './newteacher';
import MemoryKoPage from './static/memoryko';
import CooperatePage from './static/cooperate';
import AllNews from './news/allnews';
import LoginGooglePage from './login/login-google';
import EpFunPage from './epfun/epfun';
import EpFunTextPage from './epfun/epfuntext';
import TutSitePage from './static/tutsite';
import EpMainPage from './ep/epMain';
import EpResultPage from './ep/epResult';
import EpExcellentPage from './ep/epExcellent';
import TutorialPage from './tutorial';
import StuLearnRecPage from './students/StuLearnRec';
import AboutPage from './static/about';
import IntoPage from './static/introduction';
import TeacherAdminPage from './teachers/TeacherAdmin';
import TeacherStuMgnPage from './teachers/TeacherStuAdmin';
import TeacherStuLearnPage from './teachers/TeacherStuLearn';
import TeacherStuStatisticPage from './teachers/TeacherStuStatistic';
import TeacherStuLoginStatPage from './teachers/TeacherStuLoginStat';
import FaqPage from './faq';
import ReLoginGooglePage from './login/relogin-google';
import LoginNTPCPage from './login/login-ntpc';
import ReLoginNTPCPage from './login/relogin-ntpc';
import EpLoginPage from './ep/epLoginPage';
import EpNewApply from './ep/epNewApply';
import EpApplyHome from './ep/epApplyHome';
import EpApplyTeacher from './ep/epApplyTeacher';
import EpApplyGroup from './ep/epApplyGroup';
import EpApplyExplore from './ep/epApplyExplore';
import EpStudentHome from './ep/epStudentHome';
import EpStudLevel1 from './ep/epStudLevel1';
import EpTeachrHome from './ep/epTeachHome';
import EpTeachLevel1 from './ep/epTeachLevel1';
import EpExpertHome from './ep/epExpertHome';
import EpExpertGroup from './ep/epExpertGroup';
import EpExpertFeedback from './ep/epExpertFeedback';
import EpExpertOpinion from './ep/epExpertOpinion';
import EpExpertComment from './ep/epExpertComment';
import EPFAQPage from './faq-ep';
import EPAboutPage from './static/epabout';

import { getAuthData, getcontrol } from './ep/tool/helper';
import { IApplyFormData, crtlInfo } from '../interfaces/EpModal';
import ApplySysContext from '../components/sysContext';
import EpStudExpert from './ep/epStudExpert';
import EpStudfeedback from './ep/epStudfeedback';
import EpStudLevel3 from './ep/epStudLevel3';
import EpTeachExpert from './ep/epTeachExpert';
import EpTeachfeedback from './ep/epTeachfeedback';
import EpTeachLevel3 from './ep/epTeachLevel3';
import EpStudResult from './ep/epStudResult';
import EpTeachResult from './ep/epTeachResult';

export type MyContextType = {
    crtlInfoList: crtlInfo[] | undefined; // 開放時間控制項
    setSharedState?: React.Dispatch<React.SetStateAction<string>>;
    setApplyInfoShare?: React.Dispatch<React.SetStateAction<IApplyFormData>>;
    applyInfoShare?: IApplyFormData;

    setCtrlInfoList: React.Dispatch<React.SetStateAction<crtlInfo[]>>;
    setEProle: React.Dispatch<React.SetStateAction<string>>;
    currentRole?: any | undefined;
    setCurrentRole: React.Dispatch<React.SetStateAction<any | undefined>>;
};

interface IPageProps {
    crtlInfoList?: any; // 定義所有元件都應該接受的屬性
}

const MyContext = createContext<MyContextType | undefined>(undefined);

const HomePage: React.FunctionComponent<IPageProps> = (props) => {
    const styles = {
        tableContainer: {
            position: 'fixed' as 'fixed',
            bottom: '20px', // 改為距離底部 20px
            right: '20px',  // 保持距離右邊 20px
            backgroundColor: '#FFCCFF',
            borderRadius: '8px',
            padding: '15px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            zIndex: 999,
            overflow: 'auto',
          },
      };
    const [showTable, setShowTable] = useState(false);
    useEffect(() => {
        // 定義鍵盤事件處理函數，並指定事件的類型為 KeyboardEvent
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.ctrlKey && event.key === 'q') {

            setShowTable(true);
          }
        };
    
        // 當組件掛載時，添加事件監聽器
        window.addEventListener('keydown', handleKeyDown);
    
        // 當組件卸載時，移除事件監聽器
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, []); // 空的依賴數組確保只在組件掛載和卸載時觸發
    const NoAccessPage = () => <h1>網頁未開放</h1>;
    useEffect(() => {
        getCtrls(sessionStorage.getItem('role') || '');
    }, []);

    const context = useContext(MyContext);
    const getCtrls = async (control: string) => {
        let ctrlInfo = await getAuthData(control);
        context?.setCtrlInfoList(ctrlInfo);
    };
    const routesEP = [
        // { path: '/eplogin', Component: EpLoginPage },
        { path: '/epNewPage', Component: EpNewApply },
      //  { path: '/epresult', Component: EpResultPage },
        { path: '/epApplyHome', Component: EpApplyHome },
        { path: '/epApplyTeacher', Component: EpApplyTeacher },
        { path: '/epApplyGroup', Component: EpApplyGroup },
        { path: '/epApplyExplore', Component: EpApplyExplore },
        { path: '/epStudentHome', Component: EpStudentHome },
        { path: '/epTeachHome', Component: EpTeachrHome },
        { path: '/epStudLevel1', Component: EpStudLevel1 },
        { path: '/epTeachLevel1', Component: EpTeachLevel1 },
        { path: '/epFAQ', Component: EPFAQPage },
        { path: '/epabout', Component: EPAboutPage },
        { path: '/epExpertHome', Component: EpExpertHome },
        { path: '/epExpertGroup', Component: EpExpertGroup },
        { path: '/epExpertFeedback', Component: EpExpertFeedback },
        { path: '/epExpertOpinion', Component: EpExpertOpinion },
        { path: '/epExpertComment', Component: EpExpertComment },
        { path: '/epStudExpert', Component: EpStudExpert },
        { path: '/epStudfeedback', Component: EpStudfeedback },
        { path: '/epStudLevel3', Component: EpStudLevel3 },
        { path: '/EpteachExpert', Component: EpTeachExpert },
        { path: '/epTeachfeedback', Component: EpTeachfeedback },
        { path: '/epTeachLevel3', Component: EpTeachLevel3 },
        { path: '/epStudResult', Component: EpStudResult },
        { path: '/epTeachResult', Component: EpTeachResult }
    ];

    const [crtlInfoList, setCtrlInfoList] = useState<crtlInfo[]>([]);
    const [EProle, setEProle] = useState<string>('');
    const [currentRole, setCurrentRole] = useState<any>('');

    const HomePack = () => {
        return (
            <>
                <TopNavPage1 />
                <MainFuncPage />
                <NewsCards />
                <FooterPage />
            </>
        );
    };

    return (
        <>
        {/* {JSON.stringify(crtlInfoList)} */}
            {showTable && (
        <div className="table-container " style={styles.tableContainer} >

<table>
            <thead>
              <tr>
                <th>index</th>
                <th>Control</th>
                <th>Action</th>
                <th>StageName</th>
                <th>StageNo</th>
           
              </tr>
            </thead>
            <tbody>
              {crtlInfoList.map((item ,index) => (
                <tr key={index}>
                  <td>{index}</td>
                  <td>{item.Control}</td>
                  <td>{item.Action}</td>
                  <td>{item.StageName}</td>
                  <td>{item.StageNo}</td>
                  {/* <td>{item.endDate}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
            {/* {EProle} */}
            {/*<MyContext.Provider value={{ setSharedState,crtlInfoList,setApplyInfoShare: setApplyInfo,applyInfoShare: applyInfo }} */}
            <ApplySysContext>
                <BrowserRouter>
                    {/* <Switch>
                    <Route path="/pptest" component={PPTestPage} />
                    <Route path="/pptest1" component={PPTestPage1} />
                    <Route path="/pptest2" component={PPTestPage2} />
                    <Route path="/pptest3" component={PPTestPage3} />
                    <Route path="/pptest4" component={PPTestPage4} />
                    <Route path="/dptest" component={DPTestPage} />
                    <Route path="/dppractice-web" component={DPPracticePageWeb} />
                    <Route path="/dppractice-mix" component={DPPracticePageMix} />
                    <Route path="/dppractice" component={DPPracticePage} />
                    <Route path="/dpref" component={DPRefPage} />
                    <Route path="/login" component={LoginPage} />
                    <Route path="/login-google" component={LoginGooglePage} />
                    <Route path="/relogin-google" component={ReLoginGooglePage} />
                    <Route path="/login-ntpc" component={LoginNTPCPage} />
                    <Route path="/relogin-ntpc" component={ReLoginNTPCPage} />
                    <Route path="/newstudent" component={NewStudentPage} />
                    <Route path="/newteacher" component={NewTeacherPage} />
                    <Route path="/memoryko" component={MemoryKoPage} />
                    <Route path="/cooperate" component={CooperatePage} />
                    <Route path="/tutorial" component={TutorialPage} />
                    <Route path="/tutsite" component={TutSitePage} />
                    <Route path="/allnews" component={AllNews} />
                    <Route exact path="/fundata" component={EpFunPage} /> 
                    <Route exact path="/funtext" component={EpFunTextPage} />
                    <Route exact path="/eptest" component={EpMainPage} />
                    <Route exact path="/faq" component={FaqPage} />
                    <Route exact path="/stulearnrec" component={StuLearnRecPage} />
                    <Route exact path="/about" component={AboutPage} />
                    <Route exact path="/introduction" component={IntoPage} />
                    <Route exact path="/teacheradmin" component={TeacherAdminPage} />
                    <Route exact path="/teacherstumgn" component={TeacherStuMgnPage} />
                    <Route exact path="/teacherstulearn" component={TeacherStuLearnPage} />
                    <Route exact path="/teacherstustat" component={TeacherStuStatisticPage} />
                    <Route exact path="/teacherstulogin" component={TeacherStuLoginStatPage} />
                    
                    <Route path="/eplogin" component={EpLoginPage} />
                    <Route path="/epNewPage" component={EpNewApply} />
                    <Route path='/epResult' component={EpResultPage} />
                    <Route path='/epExcellent' component={EpExcellentPage} />
                    <Route path="/epApplyHome" component={EpApplyHome} />
                    <Route path="/epApplyTeacher" component={EpApplyTeacher} />
                    <Route path="/epApplyGroup" component={EpApplyGroup} />
                    <Route path="/epApplyExplore" component={EpApplyExplore} />
                    <Route path="/epStudentHome" component={EpStudentHome} />
                    <Route path="/epTeachHome" component={EpTeachrHome} />
                    <Route path="/epStudLevel1" component={EpStudLevel1} />
                    <Route path="/epTeachLevel1" component={EpTeachLevel1} />
                    <Route path="/epFAQ" component={EPFAQPage} />
                    <Route path="/epabout" component={EPAboutPage} />
                    
                    <Route path="/epExpertHome" component={EpExpertHome} /> 
                    <Route path="/epExpertGroup" component={EpExpertGroup} /> 
                    <Route path="/epExpertFeedback" component={EpExpertFeedback} /> 
                    <Route path="/epExpertOpinion" component={EpExpertOpinion} /> 
                    <Route path="/epExpertComment" component={EpExpertComment} /> 
                    
                    
                    <Route path="/epStudExpert" component={EpStudExpert} /> 
                    <Route path="/epStudfeedback" component={EpStudfeedback} /> 
                    <Route path="/epStudLevel3" component={EpStudLevel3} /> 
                    <Route path="/EpteachExpert" component={EpTeachExpert} /> 
                    <Route path="/epTeachfeedback" component={EpTeachfeedback} /> 
                    <Route path="/epTeachLevel3" component={EpTeachLevel3} /> 
                    <Route path="/epStudResult" component={EpStudResult} /> 
                    <Route path="/epTeachResult" component={EpTeachResult} /> 
                    
                    
                    <Route path="/" component={HomePack} />
                    </Switch> */}

                    <Routes>
                        <Route path="/pptest" element={<PPTestPage />} />
                        <Route path="/pptest1" element={<PPTestPage1 />} />
                        <Route path="/pptest2" element={<PPTestPage2 />} />
                        <Route path="/pptest3" element={<PPTestPage3 />} />
                        <Route path="/pptest4" element={<PPTestPage4 />} />
                        <Route path="/dptest" element={<DPTestPage />} />

                        <Route path="/dppractice-web" element={<DPPracticePageWeb />} />
                        <Route path="/dppractice-mix" element={<DPPracticePageMix />} />
                        <Route path="/dppractice" element={<DPPracticePage />} />
                        <Route path="/dpref" element={<DPRefPage />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/login-google" element={<LoginGooglePage />} />
                        <Route path="/relogin-google" element={<ReLoginGooglePage />} />
                        <Route path="/login-ntpc" element={<LoginNTPCPage />} />
                        <Route path="/relogin-ntpc" element={<ReLoginNTPCPage />} />
                        <Route path="/newstudent" element={<NewStudentPage />} />
                        <Route path="/newteacher" element={<NewTeacherPage />} />
                        <Route path="/memoryko" element={<MemoryKoPage />} />
                        <Route path="/cooperate" element={<CooperatePage />} />
                        <Route path="/tutorial" element={<TutorialPage />} />
                        <Route path="/tutsite" element={<TutSitePage />} />
                        <Route path="/allnews" element={<AllNews />} />
                        <Route path="/fundata" element={<EpFunPage />} />
                        <Route path="/funtext" element={<EpFunTextPage />} />
                        <Route path="/eptest" element={<EpMainPage />} />
                        <Route path='/epresult' element={<EpResultPage />} />
                        <Route path='/epexcellent' element={<EpExcellentPage />} />
                        <Route path="/faq" element={<FaqPage />} />
                        <Route path="/stulearnrec" element={<StuLearnRecPage />} />
                        <Route path="/about" element={<AboutPage />} />
                        <Route path="/introduction" element={<IntoPage />} />
                        <Route path="/teacheradmin" element={<TeacherAdminPage />} />
                        <Route path="/teacherstumgn" element={<TeacherStuMgnPage />} />
                        <Route path="/teacherstulearn" element={<TeacherStuLearnPage />} />
                        <Route path="/teacherstustat" element={<TeacherStuStatisticPage />} />
                        <Route path="/teacherstulogin" element={<TeacherStuLoginStatPage />} />
                        {/*
        <Route path="/epNewPage" element={<EpNewApply />} />
        <Route path="/epresult" element={<EpResultPage />} />
        <Route path="/epApplyHome" element={<EpApplyHome />} />
        <Route path="/epApplyTeacher" element={<EpApplyTeacher />} />
        <Route path="/epApplyGroup" element={<EpApplyGroup />} />
        <Route path="/epApplyExplore" element={<EpApplyExplore />} />
        <Route path="/epStudentHome" element={<EpStudentHome />} />
        <Route path="/epTeachHome" element={<EpTeachrHome />} />
        <Route path="/epStudLevel1" element={<EpStudLevel1 />} />
        <Route path="/epTeachLevel1" element={<EpTeachLevel1 />} />
        <Route path="/epFAQ" element={<EPFAQPage />} />
        <Route path="/epabout" element={<EPAboutPage />} />
        
        <Route path="/epExpertHome" element={<EpExpertHome />} />
        <Route path="/epExpertGroup" element={<EpExpertGroup />} />
        <Route path="/epExpertFeedback" element={<EpExpertFeedback />} />
        <Route path="/epExpertOpinion" element={<EpExpertOpinion />} />
        <Route path="/epExpertComment" element={<EpExpertComment />} />
        
        <Route path="/epStudExpert" element={<EpStudExpert />} />
        <Route path="/epStudfeedback" element={<EpStudfeedback />} />
        <Route path="/epStudLevel3" element={<EpStudLevel3 />} />
        <Route path="/EpteachExpert" element={<EpTeachExpert />} />
        <Route path="/epTeachfeedback" element={<EpTeachfeedback />} />
        <Route path="/epTeachLevel3" element={<EpTeachLevel3 />} />
        <Route path="/epStudResult" element={<EpStudResult />} />
        <Route path="/epTeachResult" element={<EpTeachResult />} /> */}

                        <Route path="/" element={<HomePack />} />
                            <Route path="/eplogin" element={<EpLoginPage />} />
                            {routesEP.map(({ path, Component }) => (
    <Route
      key={path}
      path={path}
      element={
        <MyContext.Provider value={{ crtlInfoList, setCtrlInfoList, setEProle, setCurrentRole, currentRole }}>
          <Component />
        </MyContext.Provider>
      }
    />
  ))}
                    </Routes>
                </BrowserRouter>
            </ApplySysContext>
            {/*</MyContext.Provider> */}
        </>
    );
};

export default HomePage;
export { MyContext };
