import React, { useContext, useEffect, useState } from 'react';
import IPageProps from '../../../interfaces/page';
import { handleLink } from '../../../modules/utils';
import { crtlInfo } from '../../../interfaces/EpModal';
import { getAuthData, getcontrol, logOut } from '../tool/helper';
import { MyContext } from '../../home';

/**epApplyHome.html  【6-4-5	小隊基本資料】*/
const TeacherFunctionBar: React.FunctionComponent<IPageProps> = (props) => {
    useEffect(() => {
      

        getCtrls(sessionStorage.getItem('role') || '');
    }, []);

    const context = useContext(MyContext);
    const getCtrls = async (control: string) => {
        let ctrlInfo = await getAuthData(control);
        context?.setCtrlInfoList(ctrlInfo);
    };

    const getCtrl = (CtrlName: string) => {
        let result;
        if(context?.crtlInfoList?.length){
            result = context?.crtlInfoList?.find((x) => {
                return x.Control.trim() === CtrlName.trim();
            });


        }
       
    
        return result;
    };
    const link = (ctrlName: string, route: string) => {
        if (getCtrl(ctrlName)) {
            handleLink(route);
        } else {
        }
    };

    const disableStyle = {
        color: 'gray',
        background: '#dddddd'
    };

    return (
        <>
            
       
            <div className="text-center text-lg-end exclude">
                <a
                    href={getCtrl('指導老師主頁') ? '/epTeachHome' : ''}
                    onClick={() => {
                        link('指導老師主頁', '/epTeachHome');
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3 "
                    style={getCtrl('指導老師主頁')  ? {} : disableStyle}
                    type="button"
                >
                    指導老師主頁
                </a>
                <a
                    href={getCtrl('階段1和2')  ? '/epTeachLevel1' : ''}
                    onClick={() => {
                        link('階段1和2', '/epTeachLevel1');
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    style={getCtrl('階段1和2')  ? {} : disableStyle}
                    type="button"
                >
                    階段1和2
                </a>
                <a
                    href={getCtrl('專家回饋') ?'/EpTeachExpert' : ''}
                    onClick={() => {
                        link('專家回饋', '/EpTeachExpert');
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    style={getCtrl('專家回饋') ? {} : disableStyle}
                    type="button"
                >
                    專家回饋
                </a>
                <a
                    href={getCtrl('階段1和2(回饋後)') ? '/epTeachfeedback' : ''}
                    onClick={() => {
                        link('階段1和2(回饋後)', '/epTeachfeedback');
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    style={getCtrl('階段1和2(回饋後)')  ? {} : disableStyle}
                    type="button"
                >
                    階段1和2(回饋後)
                </a>

                <a
                    href={getCtrl('階段3和4') ? '/epTeachLevel3' : ''}
                    onClick={() => {
                        link('階段3和4', '/epTeachLevel3');
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    style={getCtrl('階段3和4')  ? {} : disableStyle}
                    type="button"
                >
                    階段3和4
                </a>

                <a
                    href={getCtrl('成果報告')  ? '/epTeachResult' : undefined}
                    onClick={() => {
                        link('成果報告', '/epTeachResult');
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap me-2 me-sm-3"
                    style={getCtrl('成果報告') ? {} : disableStyle}
                    type="button"
                >
                    成果報告
                </a>
                <a
                
                    onClick={() => {
                        logOut();
                    }}
                    className="btn btn-outline-secondary btn-round text-nowrap"
                    type="button"
                >
                    登出
                </a>
            </div>
        </>
    );
};
export default TeacherFunctionBar;
