import axios, { AxiosError } from 'axios'
import  config from '../config/config';
import IAdNews from '../interfaces/AdNews';
import {htmlToText} from 'html-to-text'

export const getAdNews=()=>{
    const url=config.server.url+`/api/AdNews`
    return axios.get(url).then((res)=>{
        const rest:IAdNews[]=res.data;
        rest.map(data=>{
            data.SendDate=data.SendDate.slice(0,10)
            data.ShortContext=htmlToText(data.Context)
        })
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}

export const getAdNewsByKeyword=(key:string)=>{
    const kword=(key==='*')?'':key;
    const url=config.server.url+`/api/AdNews?keyword=${kword}`
    return axios.get(url).then((res)=>{
        const rest:IAdNews[]=res.data;
        rest.map(data=>{
            data.SendDate=data.SendDate.slice(0,10)
        })
        return rest;
    })
    .catch((error:AxiosError) => {
        throw error;
    });
}



